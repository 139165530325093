<template>
    <div class="user flex">
        <div class="aside">
            <div class="avater">
                <el-image :src="userAvatarUrl" :preview-src-list="[userAvatarUrl]"></el-image>
            </div>
            <div class="name_phone">
                <div class="name">{{ userName }}</div>
                <div class="phone">手机号:{{ userPhone }}</div>
            </div>
            <div class="NavBox f-w-b">
                <div class="f-cl-c-c" :class="$route.name == 'userBaseInfo' ? 'noitem' : 'item'"
                    @click="toSkip('userBaseInfo')">
                    <img v-if="$route.name == 'userBaseInfo'" src="../../assets/user/nopersonal.png" alt="">
                    <img v-else src="../../assets/user/personal.png" alt="">
                    <div class="text">个人信息</div>
                </div>
                <div class="item f-cl-c-c" :class="$route.name == 'setting' ? 'noitem' : 'item'" @click="toSkip('setting')">
                    <img v-if="$route.name == 'setting'" src="../../assets/user/nosetting.png" alt="">
                    <img v-else src="../../assets/user/setting.png" alt="">
                    <div class="text">设置</div>
                </div>
                <div class="item f-cl-c-c" :class="$route.name == 'collect' ? 'noitem' : 'item'" @click="toSkip('collect')">
                    <img v-if="$route.name == 'collect'" src="../../assets/user/nocollect.png" alt="">
                    <img v-else src="../../assets/user/collect.png" alt="">
                    <div class="text">我的收藏</div>
                </div>
                <div class="item f-cl-c-c" :class="$route.name == 'footprint' ? 'noitem' : 'item'"
                    @click="toSkip('footprint')">
                    <img v-if="$route.name == 'footprint'" src="../../assets/user/nofootprint.png" alt="">
                    <img v-else src="../../assets/user/footprint.png" alt="">
                    <div class="text">足迹</div>
                </div>
                <div class="item f-cl-c-c" :class="$route.name == 'find' ? 'noitem' : 'item'"
                    @click="toSkip('find')">
                    <img v-if="$route.name == 'find'" src="../../assets/user/find.png" alt="">
                    <img v-else src="../../assets/user/nofind.png" alt="">
                    <div class="text">我在找料</div>
                </div>
            </div>
        </div>
        <el-card class="main f1">
            <router-view></router-view>
        </el-card>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'user',
    data() {
        return {
            asideList: []
        }
    },
    computed: {
        ...mapState(['userName', 'userAvatarUrl', 'userPhone'])
    },
    created() {
        let allRouterList = this.$router.options.routes,
            asideLists = allRouterList.map(item => {
                if (item.name == 'user') {
                    return item
                }
            }),
            asideList = this.$common.filterUndefined(asideLists);
        this.asideList = asideList[0].children[0].children;
    },
    methods: {
        toSkip(name) {
            this.$router.push({
                name
            });
            this.$setStorage('fetchDataSize', 10)
            this.$setStorage('fetchDataPage', 1)
        },
    }
}
</script>

<style lang="less" scoped>
.user {
    width: 1200px;
    margin: 60px auto;

    .aside {
        width: 220px;
        height: 580px;
        margin-left: 1px;
        margin-right: 30px;
        position: relative;
        background-color: #F6F8FB;
        // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        -webkit-box-shadow: 0px 3px 3px #c8c8c8;
        -moz-box-shadow: 0px 3px 3px #c8c8c8;
        box-shadow: 0px 3px 3px #c8c8c8;
        // border: 1px solid #999;
        border-radius: 10px;
        padding: 40px 60px;

        .avater {
            width: 100%;
            // position: absolute;
            // top: 0;
            // z-index: 1;


            .el-image {
                margin: auto;
                display: block;
                width: 100px;
                height: 100px;
                border-radius: 50%;
            }
        }

        .name_phone {
            margin-top: 30px;
            text-align: center;
            padding-bottom: 40px;
            border-bottom: 1px solid #F5F6F7;

            .name {
                color: #333333;
                font-size: 24px;
            }

            .phone {
                margin-top: 15px;
                color: #999999;
                font-size: 14px;
            }
        }

        .NavBox {
            margin-top: 30px;

            .item {
                // border: 1px solid #333;
                width: 100px;
                height: 100px;
                margin-bottom: 20px;
                border-radius: 5px;
                cursor: pointer;

                img {
                    margin-bottom: 15px;
                }

                text {
                    font-size: 16px;
                    color: #333333;
                }
            }

            .noitem {
                width: 100px;
                height: 100px;
                margin-bottom: 20px;
                border-radius: 5px;
                background: linear-gradient(101deg, #006EF2 0%, #33A6F9 100%);
                cursor: pointer;

                img {
                    margin-bottom: 15px;
                }

                .text {
                    font-size: 16px;
                    color: #fff;
                }
            }
        }

        .user-left-contant {
            position: absolute;
            top: 85px;
            width: 100%;
            height: 400px;
            background: #fff;
            border: 1px solid #000;
            box-sizing: border-box;

            .container {
                width: 100%;
                text-align: center;
                padding-top: 80px;

                .user-name {
                    font-size: 18px;
                    font-weight: bold;
                }

                .el-menu {
                    margin-top: 10px;

                    .el-menu-item {
                        font-size: 16px;
                        text-align: left;
                    }

                    .is-active {
                        background-color: #64e1fd;
                        color: #000000;
                    }
                }

            }
        }
    }

    .main {
        background-color: #fff;
        padding: 20px;
        -webkit-box-shadow: 0px 3px 3px #c8c8c8;
        -moz-box-shadow: 0px 3px 3px #c8c8c8;
        box-shadow: 0px 3px 3px #c8c8c8;
    }

    /deep/ .el-avatar {
        img {
            width: 100%;
        }
    }
}
</style>